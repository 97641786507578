import React from 'react';
// import { Link } from 'gatsby'

// import logo from '../img/logo.svg'
// import facebook from '../img/social/facebook.svg'
// import instagram from '../img/social/instagram.svg'
import twitter from '../img/social/twitter.svg';
// import vimeo from '../img/social/vimeo.svg'

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer">
        <a title="twitter" href="https://twitter.com/ad_phillips">
          <img
            className="fas fa-lg"
            src={twitter}
            alt="Twitter"
            style={{ width: '1em', height: '1em' }}
          />
        </a>
      </footer>
    );
  }
}

export default Footer;
